import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 8-8-8\\@70% 1RM`}</p>
    <p>{`Hamstring Curls 12-12-12`}</p>
    <p>{`then,`}</p>
    <p>{`For time:`}</p>
    <p>{`30-Burpee Box Jump Overs (24/20)`}</p>
    <p>{`30-K2E’s`}</p>
    <p>{`30-Step Ups (15/leg)`}</p>
    <p>{`30-Back Extensions`}</p>
    <p>{`30-OH Walking Lunge Steps (15/leg, 45/25`}{`#`}{`)`}</p>
    <p>{`30-GHD Situps`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{` This Saturday is our monthly free class so bring a friend! 8:00 &
9:00am at The Ville or 10:00 & 11:00am at East.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There will be no CrossFit Kids this Saturday.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      